import React, { useEffect, useRef, useState, useContext } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BrowserView, MobileView } from 'react-device-detect';
import { validEmail } from '../../Components/Elements/Regex';
import Alert from 'react-bootstrap/Alert';
import { useLocation } from "react-router-dom";
import DataContext from "../Elements/eventContext";
import ScrollToTop from "react-scroll-to-top";
import Modal from "react-bootstrap/Modal";
import CartModal from "../Modals/cart_modal";

function Footer() {

  const location = useLocation();
  const didMountRef = useRef(true);
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }
  const [settingImagePath, setSettingImagePath] = useState("");
  const [footer1, setFooter1] = useState("");
  const [footer2, setFooter2] = useState("");
  const [footer3, setFooter3] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [setSession, SetSession] = useState('')
  const [accountStatus, SetAccountStatus] = useState(false)
  const [whatsappUrl, setWhatsappUrl] = useState('')
  const contextValues = useContext(DataContext)
  const [newsletterDetails, setNewsletterDetails] = useState({
    newsletter_email: "",
  });
  useEffect(() => {
    if (didMountRef.current) {
      // getSettingsData();
      getFooterData();
      SetSession(localStorage.getItem('USER_TOKEN'))
      const currentPath = location.pathname;
      const isAccountOverview = currentPath.includes("/account/account-overview");
      const isAddressPage = currentPath.includes("/account/address");
      const isWishlistPage = currentPath.includes("/account/wishlist");
      const ishelpandsupportPage = currentPath.includes("/account/help-and-support");
      const isaboutusPage = currentPath.includes("/account/about-us");

      if (isAccountOverview || isWishlistPage || isAddressPage || ishelpandsupportPage || isaboutusPage) {
        SetAccountStatus(true)
      } else {
        SetAccountStatus(false)
      }
    }
    didMountRef.current = false;
  }, []);

  const getFooterData = () => {
    ApiService.fetchData("footer").then((res) => {
      if (res.status == "success") {
        if (res.footerData) {
          setFooter1(res.footerData.footer_desc1);
          setFooter2(res.footerData.footer_desc2);
          setFooter3(res.footerData.footer_desc3);
        }
      }
    });
  };
  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setNewsletterDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetNewsletterForm = () => {
    setNewsletterDetails({ newsletter_email: "" });
  };
  const newsletterProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("newsletterRequired");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
        myElements[i].style.border = '';
      }
    }
    if (counter === 0) {  
      setErrorMessage("");
      if (!validEmail.test(newsletterDetails.newsletter_email)) {
        setErrorMessage("Please enter valid Email Id");
        return false;
      }
      ApiService.postData('newsletter-process', newsletterDetails).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message)
          resetNewsletterForm();
          setTimeout(() => {
            setSuccessMessage('')
          }, 2000);
        } else {
          setErrorMessage(res.message)
          setTimeout(() => {
            setErrorMessage('')
          }, 2000);
        }
      });
    }
  };

  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  return (
    <>

      <BrowserView>
        <div className="footerIocn">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="footerIocnBox">
                  <div className="ficon">
                    <img src="/img/f05.png" alt="Shipping Icon" />
                  </div>
                  <div className="ftext">
                    <h6 className="mb-1">Free Shipping</h6>
                    <p className="tx-12 mb-0">Order above Rs.500</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footerIocnBox">
                <div className="ficon">
                  <img src="/img/f04.png" alt="Save Icon" />
                </div>
                <div className="ftext">
                  <h6 class="mb-1">Save Money</h6>
                  <p className="tx-12 mb-0">All products at lowest price</p>
                </div>
              </div>
              </div>
              <div className="col-lg-3">
                <div className="footerIocnBox">
                  <div className="ficon">
                    <img src="/img/f01.png" alt="Deal Icon" />
                  </div>
                  <div className="ftext">
                    <h6 className="mb-1">Best Deal Offer</h6>
                    <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footerIocnBox">
                  <div className="ficon"><img src="/img/f02.png" alt="Quality Icon" />
                  </div>
                  <div className="ftext">
                    <h6 className="mb-1">Quality assurance </h6>
                    <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="footerTop">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <h3>Join our newsletter</h3>
                  <p className="mb-0">Register now to get latest updates on promotions & coupons.Don’t worry, we not spam!</p>
                </div>
                <div className="col-lg-5">
                  <div className="subscribe-form">
                    <input type="email" placeholder="Enter your email here.." name="newsletter_email" value="" />
                    <button type="button" class="subscribe-button">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footerMiddle" style={{ background: 'url(/img/footerbg.jpg)' }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>
                  <h2 class="footer-title mb-20">Follow Us On</h2>

                  <ul className="social-footer">
                    {contextValues.settingData.facebook_url != null ? (
                      <li>
                        <a href={contextValues.settingData.facebook_url} target="new">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}

                    {contextValues.settingData.twitter_url != null ? (
                      <li>
                        <a href={contextValues.settingData.twitter_url} target="new">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}

                    {contextValues.settingData.pinterest_url != null ? (
                      <li>
                        <a href={contextValues.settingData.pinterest_url} target="new">
                          <i className="fab fa-pinterest"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}

                    {contextValues.settingData.instagram_url != null ? (
                      <li>
                        <a href={contextValues.settingData.instagram_url} target="new">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}


                  </ul>



                </div>
                <div className="col-lg-2">
                  <div dangerouslySetInnerHTML={{ __html: footer2 }}></div>
                </div>
                <div className="col-lg-2">
                  <div dangerouslySetInnerHTML={{ __html: footer3 }}></div>
                </div>
                <div className="col-lg-4">
                  <h2 class="footer-title mb-20">Contact Info</h2>
                  <ul className="footerContact"> 
                    <li>
                      <div className="fcbix">
                        <div className="fcbix-icon"><i className="ri-map-pin-line"></i></div>
                        <div className="fcbix-text">
                          <a href="https://maps.app.goo.gl/nUtucKXEkBsXkkcL6" target="new">{contextValues.settingData.address}</a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="fcbix">
                        <div className="fcbix-icon"><i className="ri-phone-line"></i></div>
                        <div className="fcbix-text"><p className="mb-1 tx-gray tx-12">24/7 Support</p>
                          <a href={"tel:" + contextValues.settingData.admin_support_mobile}> {"+91"}-{contextValues.settingData.admin_support_mobile}</a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="fcbix">
                        <div className="fcbix-icon">
                          <i className="ri-mail-send-line"></i>
                        </div>
                        <div className="fcbix-text">
                          <p className="mb-1 tx-gray tx-12">Need help with your order?</p>
                          <a href={"mailto:" + contextValues.settingData.admin_email}> {contextValues.settingData.admin_email}</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footerBottom">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  Copyright 2024 © All Right Reserved Swadeshi Pitara
                </div>
                <div className="col-lg-6">
                  <div className="d-flex justify-content-end">
                    <img src="/img/payments.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>





      </BrowserView>



      <MobileView>
      <div className="footerIocn">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="footerIocnBox">
                  <div className="ficon">
                    <img src="/img/f05.png" alt="Shipping Icon" />
                  </div>
                  <div className="ftext">
                    <h6 className="mb-1">Free Shipping</h6>
                    <p className="tx-12 mb-0">Order above Rs.500</p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="footerIocnBox">
                <div className="ficon">
                  <img src="/img/f04.png" alt="Save Icon" />
                </div>
                <div className="ftext">
                  <h6 class="mb-1">Save Money</h6>
                  <p className="tx-12 mb-0">All products at lowest price</p>
                </div>
              </div>
              </div>
              <div className="col-6">
                <div className="footerIocnBox">
                  <div className="ficon">
                    <img src="/img/f01.png" alt="Deal Icon" />
                  </div>
                  <div className="ftext">
                    <h6 className="mb-1">Best Deal Offer</h6>
                    <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="footerIocnBox">
                  <div className="ficon"><img src="/img/f02.png" alt="Quality Icon" />
                  </div>
                  <div className="ftext">
                    <h6 className="mb-1">Quality assurance </h6>
                    <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}
export default Footer